import React from 'react';

import styles from './styles.module.scss';

interface Props {
  error: string;
}

const ErrorMessage = ({ error }: Props) => {
  return (
    <div className={styles.error}>
      <p className={styles.msg}>{error}</p>
    </div>
  );
};

export default ErrorMessage;
