import React, { useRef, useState } from 'react';
import cc from 'classcat';

import CategoryButton from '@/components/CategoryButton';

import ChevronIcon from '@/images/icons/chevron--fat.svg';

import styles from './styles.module.scss';

interface Props {
  title: string;
  categories?: Nullable<(CategoryFragment | ProductCategoryFragment)[]>;
  slider?: boolean;
  whiteText?: boolean;
}
const CategoriesGrid = ({ categories, title, slider, whiteText }: Props) => {
  const catergoryRef = useRef<HTMLDivElement>(null);
  const [fullScroll, setFullScroll] = useState(false);
  const [scrollDist, setScrollDist] = useState(200);
  function scrollCatergory() {
    if (catergoryRef != null && catergoryRef.current) {
      if (!fullScroll) {
        setScrollDist(scrollDist + 200);
      } else {
        setFullScroll(false);
        setScrollDist(200);
      }
      catergoryRef.current.scrollLeft = scrollDist;
      if (scrollDist > catergoryRef.current.scrollWidth - catergoryRef.current.clientWidth) {
        setFullScroll(true);
        setScrollDist(0);
      }
    }
  }
  return (
    <div
      className={cc({
        [styles.categoriesGrid]: true,
        [styles.slider]: slider,
        [styles.whiteText]: whiteText,
      })}
    >
      <h3 className={styles.title}>
        {title}{' '}
        {slider && (
          <button onClick={scrollCatergory} className={styles.scroller} data-test-scroll-dis={scrollDist}>
            <ChevronIcon />
          </button>
        )}
      </h3>
      <div ref={catergoryRef} className={styles.categories} data-test-full-scroll={fullScroll}>
        {categories?.map(
          (c: Maybe<CategoryFragment | ProductCategoryFragment>) =>
            c &&
            c?.__typename && (
              <CategoryButton
                category={c}
                key={c.slug}
                productCategory={c.__typename === 'productCategories_productCategories_Entry'}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default CategoriesGrid;
