import { mergeWith } from 'lodash';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { OperationResult } from 'urql';

import categoryQuery from '@/components/CategoriesSelect/query.graphql';
import footerQuery from '@/components/layout/Footer/query.graphql';
import headerQuery from '@/components/layout/Header/components/MenuBar/query.graphql';
import userQuery from '@/graphql/queries/user.graphql';
import { initStores, serializeStores } from '@/hooks/useStores';
import { SSRContextServerContext } from '@/typings/SSRContext';

import GraphQLError from './GraphQLError';
import { prefetchSEO } from './seo';
import { createClient } from './urqlClient';
import UserService from './UserService';

interface DefaultProps {
  uri: string;
}

type ServerSidePropsCallback<T> =
  | Promise<GetServerSidePropsResult<T | DefaultProps> | void>
  | GetServerSidePropsResult<T | DefaultProps>
  | void;

export async function getServerSidePropsWithGlobalData<T>(
  ctx: GetServerSidePropsContext,
  fn: (context: SSRContextServerContext) => ServerSidePropsCallback<T>,
) {
  const [urqlClient, ssrCache] = createClient(ctx);
  const [urqlNoCacheClient] = createClient(ctx);
  const site = ctx?.locale || 'mymotorewards';
  const userCookie = ctx?.req?.headers?.cookie || '';

  // prefetch user by cookie or uuid in `id` param
  const userSession = await UserService.loggedInSSR(userCookie);

  // Init stores
  const stores = initStores();
  await stores.globals.init(urqlNoCacheClient, site);
  await stores.cart.init(urqlNoCacheClient, userCookie);
  await stores.userStore.init(userSession);

  // User, header and footer queries
  const results = await Promise.allSettled([
    await urqlClient
      .query<UserQuery, UserQueryVariables>(userQuery, {}, { fetchOptions: { headers: { cookie: userCookie } } })
      .toPromise(),
    await urqlClient.query<HeaderLinksQuery, HeaderLinksQueryVariables>(headerQuery, {}).toPromise(),
    await urqlClient.query<CategoriesQuery, CategoriesQueryVariables>(categoryQuery, {}).toPromise(),
    await urqlClient.query<FooterQuery, FooterQueryVariables>(footerQuery, {}).toPromise(),
    await prefetchSEO(
      {
        nextCtx: ctx,
        urqlClient,
        urqlNoCacheClient,
        stores,
      },
      ctx?.resolvedUrl?.replace(/\?.*$/, ''),
    ),
  ]);

  // Throw the first error
  for (const result of results) {
    if (result.status === 'fulfilled') {
      if (result.value.error) {
        throw new GraphQLError(result.value.error, result.value.operation);
      }
    }
  }

  const userQueryResult = (results[0] as any).value as OperationResult<UserQuery>;
  await stores.userStore.ensureNeatIdeasToken(
    ctx,
    userQueryResult.data?.userDetails?.email,
    userQueryResult.data?.userDetails?.firstName,
    userQueryResult.data?.userDetails?.lastName,
  );

  const result = await fn({
    nextCtx: ctx,
    urqlClient,
    urqlNoCacheClient,
    stores,
  });
  const urqlState = ssrCache?.extractData();

  return mergeWith(
    {
      props: {
        urqlState,
        mobxState: serializeStores(stores),
      },
    },
    result || {},
  );
}
