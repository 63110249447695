import React from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { GetServerSideProps } from 'next';
import { useQuery } from 'urql';

import Button from '@/components/Button';
import CardGrid from '@/components/CardGrid';
import CategoriesGrid from '@/components/CategoriesGrid';
import PromoBanner from '@/components/PromoBanner';
import SignInBlock from '@/components/SignInBlock';
import useIsMobile from '@/hooks/useIsMobile';
import useStore from '@/hooks/useStore';
import { getServerSidePropsWithGlobalData } from '@/lib/getServerSideProps';
import GraphQLError from '@/lib/GraphQLError';
import { extractFragment } from '@/lib/GraphQLHelper';
import { ErrorPage } from '@/pages/_error';
import PublicEnvironment from '@/stores/PublicEnvironment';

import query from './query.graphql';

import styles from './styles.module.scss';

const Index = () => {
  const { exclusive, loggedIn, neatIdeasToken } = useStore('userStore');
  const { prettySiteName } = useStore('globals');
  const [isMobile, isLtLarge] = useIsMobile(768, 1200);
  const [{ data, error, fetching }] = useQuery<PlatformIndexQuery>({
    query,
    variables: {
      exclusive,
    },
  });

  if (error) {
    return <ErrorPage statusCode={500} err={error} />;
  }

  if (!fetching && !data?.entry) {
    return <ErrorPage statusCode={404} err={error} />;
  }

  const heroEntry = extractFragment<HomePageEntryFragment>(data?.entry);
  const heroImageSrc = heroEntry?.heroImage?.[0]?.url;

  return (
    <div data-testid="platformId" className={styles.home}>
      <div
        className={cc({
          [styles.hero]: true,
          [styles.loggedIn]: loggedIn,
        })}
      >
        <div className={styles.gradient} />
        {heroImageSrc && <img className={styles.backgroundImage} src={heroImageSrc} alt="" />}
      </div>
      <div
        className={cc({
          [styles.body]: true,
          [styles.loggedIn]: loggedIn,
        })}
      >
        <div className={styles.top}>
          {loggedIn ? (
            <>
              {isMobile ? (
                <h1>Welcome, {data?.userDetails?.firstName}</h1>
              ) : (
                <h1>
                  Hello {data?.userDetails?.firstName}. Welcome to {prettySiteName}
                </h1>
              )}
              {PublicEnvironment.get('ENV') !== 'production' ||
              data?.userDetails?.email?.match(/@(autoleaguex?|mymoto)\.com\.au/) ? (
                <p>
                  <a
                    href={`https://${PublicEnvironment.get(
                      'NEAT_IDEAS_STORE',
                    )}/mymotorewards/handoversso/index/ectoken/key/${neatIdeasToken}?cat=&parameter=&url=`}
                    target="_blank"
                    className={styles.neatIdeasLink}
                    rel="noreferrer"
                  >
                    Visit Neat Ideas
                  </a>
                </p>
              ) : null}
            </>
          ) : (
            <>
              {heroEntry?.body && <div className={styles.text} dangerouslySetInnerHTML={{ __html: heroEntry.body }} />}
              {isMobile ? (
                <div className={styles.mobileSignin}>
                  <Button href="/login">Sign in</Button>
                  <a href="/activate" className={styles.activate}>
                    New member? Activate your card now
                  </a>
                </div>
              ) : (
                <SignInBlock />
              )}
            </>
          )}
        </div>
        {heroEntry?.promoBanner?.[0] && <PromoBanner promoBanner={heroEntry.promoBanner[0]} />}
        <div className={styles.categoriesGrid}>
          <CategoriesGrid
            title={isMobile ? 'Browse categories' : 'Browse offers by category'}
            categories={data?.categories as CategoryFragment[]}
            slider={isLtLarge}
          />
        </div>
        <CardGrid title="Popular offers" cards={data?.popular as OfferFragment[]} slider={isMobile} small={isMobile} />
        <div className={styles.categoriesGrid}>
          <CategoriesGrid
            title={isMobile ? 'Browse categories' : 'Browse products by category'}
            categories={data?.productCategories as unknown as ProductCategoryFragment[]}
            slider={isMobile}
          />
        </div>
        <CardGrid
          title="Featured products"
          cards={data?.products as ProductOfferFragment[]}
          slider={isMobile}
          small={isMobile}
        />
        <div className={styles.grid}>
          <CardGrid
            title="News & Events"
            cards={data?.news as NewsEntryFragment[]}
            slider={isMobile}
            small={isMobile}
          />
        </div>
        {loggedIn && (
          <div className={styles.grid}>
            <CardGrid
              title="Competitions"
              cards={data?.competitions as CompetitionsEntryFragment[]}
              slider={isMobile}
              small={isMobile}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) =>
  await getServerSidePropsWithGlobalData(ctx, async (ctx) => {
    const { stores, urqlClient, nextCtx } = ctx;

    const platformIndexResult = await urqlClient
      .query<PlatformIndexQuery>(
        query,
        {
          exclusive: stores.userStore.exclusive,
        },
        { fetchOptions: { headers: { cookie: nextCtx?.req?.headers?.cookie || '' } } },
      )
      .toPromise();

    if (platformIndexResult.error) {
      throw new GraphQLError(platformIndexResult.error, platformIndexResult.operation);
    }

    return { props: undefined };
  });

export default observer(Index);
