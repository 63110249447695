import { CombinedError, Operation } from 'urql';

export default class GraphQLError extends Error {
  combinedError: CombinedError;
  operation: Operation;

  constructor(combinedError: CombinedError, operation: Operation) {
    super();
    this.operation = operation;
    this.combinedError = combinedError;
    const topError = combinedError?.graphQLErrors?.[0]?.message || combinedError?.networkError?.message || 'unknown';
    this.name = `GraphQLError: ${topError}`;
  }
}
