import React, { useState } from 'react';
import cc from 'classcat';
import { useEffectOnce } from 'react-use';
import Spinner from '@atlaskit/spinner';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import Button from '@/components/Button';
import Field from '@/components/forms/Field';
import { trackEvent } from '@/lib/GA';
import SocialAuthService from '@/lib/SocialAuthService';
import UserService from '@/lib/UserService';

import GoogleIcon from '@/images/icons/google.svg';

import ErrorMessage from '../ErrorMessage';

import styles from './styles.module.scss';

const SignInBlock = () => {
  const initialValues: UserService.LoginValues = { loginName: '', password: '', rememberMe: false };
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffectOnce(() => {
    SocialAuthService.getFlashErrors().then((provider) => {
      if (!!provider) {
        setError(
          `We couldn't log you in with ${provider}. Please login with your email and password, and review your social login settings.`,
        );
      }
    });
  });

  const onSubmit = async (values: UserService.LoginValues) => {
    setSubmitting(true);
    const response = await UserService.loginUser(values);

    if (!response?.errors && response?.user) {
      trackEvent('Login', 'Clicked Log in button');
      window.location.assign('/');
      return;
    } else if (response?.message) {
      setError(response.message);
    } else {
      setError('Unable to login');
    }

    if (process.env.NODE_ENV === 'development') {
      console.error(response);
    }

    setSubmitting(false);
  };

  const onSocialClick = (site: 'google') => {
    UserService.socialLogin(site);
    trackEvent('Login', 'Clicked Social Login Button', site);
  };

  return (
    <div className={styles.signInBlock}>
      <>
        <h3>Sign in</h3>
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={SignInBlock.validationSchema}>
          {({ errors, values }) => (
            <Form>
              <Field name="loginName" type="email" required label="Email" errors={errors} formik />
              <div className={cc({ [styles.passwordBlock]: true, [styles.visible]: !!values?.loginName })}>
                <Field name="password" type="password" required label="Password" errors={errors} formik />
              </div>
              {error && <ErrorMessage error={error} />}
              {!submitting ? (
                <div className={styles.button}>
                  <Button type="submit" size="large" design="highlight">
                    Login
                  </Button>
                </div>
              ) : (
                <div className={styles.spinner}>
                  <Spinner size="large" />
                </div>
              )}
            </Form>
          )}
        </Formik>
        <p>
          Forgot your password? <a href="/forgot-password">Click here.</a>
        </p>
        <p>
          <strong>
            New member? <a href="/activate">Activate your membership</a>
          </strong>
        </p>
        <div className={styles.socialLogin}>
          <h3>Or sign in with</h3>
          <div className={styles.buttons}>
            <Button design="social" size="large" onClick={() => onSocialClick('google')}>
              <GoogleIcon />
              Google
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};

SignInBlock.validationSchema = object({
  loginName: string().required('Email is required'),
  password: string().required('Please enter your password'),
});

export default SignInBlock;
