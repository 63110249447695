import { captureException } from '@sentry/browser';
import capitalize from 'lodash/capitalize';

export default class SocialAuthService {
  static async getFlashErrors() {
    try {
      const res = await fetch('/api/flash/errors', {
        headers: { Accept: 'application/json' },
      });
      const json = await res.json();
      if (!!json.error) {
        return capitalize(json.provider as string);
      }

      return '';
    } catch (e) {
      captureException(e);
      return '';
    }
  }
}
