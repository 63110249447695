import seoNoRedirectQuery from '@/graphql/queries/seoNoRedirect.graphql';
import GraphQLError from '@/lib/GraphQLError';
import { SSRContext } from '@/typings/SSRContext';

/**
 * Prefetch seo so its already cached for client side
 * @param context
 * @param uri
 * @throws {GraphQLError}
 * @returns result
 */
export async function prefetchSEO(context: SSRContext, uri: string) {
  const result = await context.urqlClient
    .query<SeoNoRedirectQuery>(seoNoRedirectQuery, {
      uri: uri.startsWith('/') ? uri : `/${uri}`,
    })
    .toPromise();

  if (result.error) {
    throw new GraphQLError(result.error, result.operation);
  }

  return result;
}

export function capitalizeName(name: string | undefined) {
  if (!name) {
    return '';
  }

  return name
    .toLowerCase()
    .replace('-region', '')
    .replace('-suburb', '')
    .replace(/-/g, ' ')
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function getMetaTags(title: string, description: string) {
  return `<title>${title}</title><meta name="title" content="${title}"><meta name="description" content="${description}"><meta content="${title}" property="og:title"><meta content="${description}" property="og:description"><meta content="${title}" name="twitter:title"><meta content="${description}" name="twitter:description">`;
}
