import React, { useMemo } from 'react';

import { useIsMobile } from '@/hooks';

import styles from './styles.module.scss';

interface Props {
  promoBanner: PromoBannerFragment;
}

const PromoBanner = ({ promoBanner }: Props) => {
  const [isMobile] = useIsMobile(768);
  const image = useMemo(() => {
    if (isMobile) {
      return promoBanner?.mobileHeroImage?.[0]?.url || '/';
    } else {
      return promoBanner?.heroImage?.[0]?.url || '/';
    }
  }, [isMobile, promoBanner?.heroImage, promoBanner?.mobileHeroImage]);
  return (
    <div className={styles.promoBanner}>
      <a href={promoBanner?.weblink || '/'} className={styles.inner}>
        <div className={styles.image}>
          <img src={image} alt="" />
        </div>
      </a>
    </div>
  );
};

export default PromoBanner;
