import React from 'react';

import { trackEvent } from '@/lib/GA';

import styles from './styles.module.scss';

interface Props {
  category: CategoryFragment | ProductCategoryFragment;
  productCategory?: boolean;
}

const CategoryButton = ({ category, productCategory }: Props) => {
  const href = productCategory ? `/shop/${category.slug}` : `/offers/${category.slug}`;

  const handleClick = () => {
    trackEvent('Category Offer', 'Clicked Category offer', `${category.title}`);
  };

  return (
    <a href={href} onClick={handleClick} className={styles.categoryButton}>
      <div className={styles.image}>
        {category?.icon?.[0]?.url && <img src={category.icon[0].url} alt={category.title!} />}
      </div>
      <div className={styles.title}>{category.title}</div>
    </a>
  );
};

export default CategoryButton;
